// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-templates-about-tsx": () => import("./../src/templates/about.tsx" /* webpackChunkName: "component---src-templates-about-tsx" */),
  "component---src-templates-contact-tsx": () => import("./../src/templates/contact.tsx" /* webpackChunkName: "component---src-templates-contact-tsx" */),
  "component---src-templates-index-tsx": () => import("./../src/templates/index.tsx" /* webpackChunkName: "component---src-templates-index-tsx" */),
  "component---src-templates-order-confirmed-tsx": () => import("./../src/templates/orderConfirmed.tsx" /* webpackChunkName: "component---src-templates-order-confirmed-tsx" */),
  "component---src-templates-shop-detail-tsx": () => import("./../src/templates/shopDetail.tsx" /* webpackChunkName: "component---src-templates-shop-detail-tsx" */),
  "component---src-templates-shop-tsx": () => import("./../src/templates/shop.tsx" /* webpackChunkName: "component---src-templates-shop-tsx" */),
  "component---src-templates-text-page-tsx": () => import("./../src/templates/textPage.tsx" /* webpackChunkName: "component---src-templates-text-page-tsx" */),
  "component---src-templates-work-tsx": () => import("./../src/templates/work.tsx" /* webpackChunkName: "component---src-templates-work-tsx" */)
}

